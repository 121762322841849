<template>
  <div class="home-body">
    <el-container>
      <el-aside width="200px" class="aside">
        <h3>自习室管理系统</h3>
        <el-menu default-active="1" background-color="#4d5174" text-color="#fff" active-text-color="#ffd04b">
          <el-menu-item index="1" @click="goPage('/adminUser')">
            <i class="el-icon-user"></i>
            <span slot="title">用户管理</span>
          </el-menu-item>

          <el-menu-item index="2" @click="goPage('/adminClass')">
            <i class="el-icon-menu"></i>
            <span slot="title">自习室管理</span>
          </el-menu-item>

          <el-menu-item index="3" @click="goPage('/adminRecharge')">
            <i class="el-icon-s-finance"></i>
            <span slot="title">充值管理</span>
          </el-menu-item>

          <el-menu-item index="4" @click="goPage('/adminLog')">
            <i class="el-icon-s-unfold"></i>
            <span slot="title">留言管理</span>
          </el-menu-item>
          <el-menu-item index="5" @click="goPage('/adminNotice')">
            <i class="el-icon-date"></i>
            <span slot="title">公告管理</span>
          </el-menu-item>
          <el-menu-item index="6" @click="goPage('/adminPicture')">
            <i class="el-icon-document"></i>
            <span slot="title">自习室使用分析</span>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-container>
        <el-header>
          <el-menu active-text-color="#dfe7ea" class="el-menu" mode="horizontal" background-color="#dfe7ea">
            <el-submenu index="2">
              <template slot="title">
                <img class="header-img" :src="headImg" alt="头像加载失败">
              </template>
              <el-menu-item index="2-1" @click="showEditDialog">修改个人信息</el-menu-item>
              <el-menu-item index="2-2" @click="logout()">退出</el-menu-item>
            </el-submenu>
          </el-menu>
        </el-header>
        <el-main>
          <router-view />
        </el-main>
        <el-footer>自习室预约小程序</el-footer>
      </el-container>
    </el-container>
    <el-dialog width="500px" title="修改个人信息" :visible.sync="updateFormIsShow">
      <el-form class="addform">
        <el-form-item label="用户名">
          <el-input v-model="currentUpdate.username" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="昵称">
          <el-input v-model="currentUpdate.nickName" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input type="password" v-model="currentUpdate.password" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="电话">
          <el-input type="text" v-model="currentUpdate.phoneNumber" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="邮箱">
          <el-input type="text" v-model="currentUpdate.email" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="头像">
          <el-upload class="avatar-uploader" action="http://123.60.85.33:8080/upload/up/" :show-file-list="false"
            :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
            <img v-if="imageUrl" :src="imageUrl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="updateFormIsShow = false">取 消</el-button>
        <el-button type="primary" @click="update()">修 改</el-button>
      </div>
    </el-dialog>


  </div>
</template>

<script>
import { logout } from "../../../network/login";
import { updateAdminUser } from "../../../network/adminUser";

export default {
  name: "Home",
  data() {
    return {
      imageUrl: '',
      name: localStorage.getItem('name'),
      headImg: localStorage.getItem('headImg'),
      currentUpdate: {
        id: null,
        username: '',
        nickName: '',
        password: '',
        phoneNumber: '',
        email: '',
        headImg: null
      },
      updateFormIsShow: false
    }
  },
  methods: {
    update() {
      const uid = localStorage.getItem('id')
      const form = {
        id: uid,
        username: this.currentUpdate.username,
        nickName: this.currentUpdate.nickName,
        password: this.currentUpdate.password,
        phoneNumber: this.currentUpdate.phoneNumber,
        email: this.currentUpdate.email,
        headImg: this.imageUrl
      }
      console.log('form', form)
      updateAdminUser(form).then(res => {
        if (res.code === 2000) {
          this.$message({
            message: '修改成功',
            type: 'success'
          });
        }
      })
      this.updateFormIsShow = false
    },
    showEditDialog() {
      this.updateFormIsShow = true;
      // 使用 $nextTick 方法等待 DOM 更新完毕后再设置激活状态
      this.$nextTick(() => {
        const menuItem = document.querySelector('.el-menu-item[index="2-1"]');
        menuItem.classList.add('is-active');
      });
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = res.filePath;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    goPage(path) {
      this.$router.push(path)
    },
    logout() {
      logout().then(res => {
        if (res.code === 2000) {
          this.$message({
            message: '退出成功',
            type: 'success'
          });
          this.$router.push("/login")
        }
      })
    },
    mounted() {

    }
  }
}
</script>

<style scoped>
.el-menu {
  background: #bce6d8;
}

.adminName {
  padding: 20%;
  background-color: #dfe7ea;
  color: #000;
}

.header-img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.home-body {
  /*height: 100vh;*/
}

.el-header {
  background: #dfe7ea;
  display: flex;
  justify-content: flex-end;
  padding-right: 40px;
  align-items: center;
  height: 100%;
}

.el-header,
.el-footer {
  color: #333;
  text-align: center;
  padding: 0;
}

.el-aside {
  background-color: #4d5174;
  color: #333;
  text-align: center;
  height: 100vh;
}

.el-main {
  color: #72ade9;
}


body>.el-container {
  margin-bottom: 40px;
}

.el-container {
  height: 100vh;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
